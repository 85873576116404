<template>
  <renderless-select
    v-bind="{
      // Pass on the props from BSelect
      ...$props,
      // But if props does not contain an error property, instead we pass on the error
      // bag provided by the validation provider. This allows the error property to
      // override the default validation.
      error: $props.error
    }"
    v-on="{
      // Pass on the listeners from BSelect
      ...$listeners,
      // But because vee-validate's ValidationProvider normally works using v-model, we
      // have to trigger the validation ourselves. We do this by overriding the 'input'
      // listener and passing the new value to the validate method we get from the
      // wrapping ValidationProvider - before calling BSelect's default input listener!
      // Failing to do so will stop the input's value from propagating.
      // We also want validation to trigger when closing the component, so we override
      // that listener as well to call validate before calling BSelect's default close
      // listeners if there was one.
      // Keep in mind that when validating on both input and close, we'll get double
      // validate invocations so we'll need to debouncing any async validation calls.
      input: newValue => {
        if (rules && $refs.validator) {
          $refs.validator.validate(newValue);
          $refs.validator.flags.dirty = true;
          $refs.validator.flags.pristine = false;
        }

        $listeners.input(newValue);
      },
      close: currentValue => {
        if (rules && $refs.validator) {
          $refs.validator.validate(currentValue);
          $refs.validator.flags.untouched = false;
          $refs.validator.flags.touched = true;
        }

        if ($listeners.close) {
          $listeners.close();
        }
      }
    }"
    v-slot="{
      value,
      hasValue,
      name,
      isMultiple,
      isSearchable,
      isDisabled,
      isOpen,
      isTruncated,
      options,
      highlightedIndex,

      contextMessages,
      errors,

      toggle,
      open,
      close,
      select,

      displayFn,
      compareFn,

      searchProps,
      searchEvents,
      activatorEvents
    }"
  >
    <validation-provider
      ref="validator"
      :rules="rules"
      :name="name"
      v-slot="validatorSlotScope"
    >
      <label-provider
        :label="$props.label"
        :name="name"
        :inline="$props.inline"
        :context-messages="{
          ...contextMessages,
          errors: contextMessages.errors
            ? contextMessages.errors
            : validatorSlotScope.errors
        }"
      >
        <on-click-outside-provider :do="close">
          <div
            class="tw-relative tw-reset"
            :aria-invalid="(validatorSlotScope.errors.length > 0).toString()"
            :aria-describedby="`${name}-describedby`"
          >
            <span class="tw-inline-block tw-w-full tw-rounded-md tw-shadow-sm">
              <button
                ref="button"
                v-on="activatorEvents"
                type="button"
                :disabled="isDisabled"
                :class="[
                  btnClass,
                  [
                    'b-select__btn',
                    'tw-cursor-pointer',
                    'tw-relative',
                    'tw-w-full',
                    'tw-rounded-md',
                    'tw-border',
                    'tw-border-gray-300',
                    'tw-bg-white',
                    'tw-pl-3',
                    'tw-pr-10',
                    'tw-py-2',
                    'tw-text-left',
                    'focus:tw-outline-none',
                    'focus:tw-shadow-outline-blue',
                    'focus:tw-border-blue-300',
                    'tw-transition',
                    'tw-ease-in-out',
                    'tw-duration-150',
                    'sm:tw-text-sm',
                    'sm:tw-leading-5'
                  ],
                  ...(isOpen
                    ? [
                        'tw-outline-none',
                        'tw-shadow-outline-blue',
                        'tw-border-blue-300'
                      ]
                    : [])
                ]"
                style="border-width: 1px; border-style: solid"
                aria-haspopup="listbox"
                :aria-expanded="isOpen ? 'true' : 'false'"
                aria-labelledby="listbox-label"
              >
                <slot
                  name="selection"
                  v-bind="{
                    value,
                    isMultiple,
                    isTruncated,
                    hasValue,
                    displayFn,
                    placeholder
                  }"
                >
                  <b-select-selection
                    v-bind="{
                      value,
                      isMultiple,
                      isTruncated,
                      hasValue,
                      displayFn,
                      placeholder
                    }"
                  />
                </slot>
                <span
                  class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-pointer-events-none"
                >
                  <font-awesome-icon
                    v-if="loading"
                    class="tw-text-gray-400"
                    :icon="['fad', 'spinner']"
                    size="lg"
                    pulse
                  />
                  <font-awesome-icon
                    v-else-if="errors && errors.length > 0"
                    class="tw-text-error"
                    size="lg"
                    :icon="['far', 'exclamation-circle']"
                  />
                  <font-awesome-icon
                    v-else
                    class="tw-text-gray-400"
                    size="lg"
                    :icon="['fal', 'sort']"
                  />
                </span>
              </button>
            </span>

            <!-- Select popover, show/hide based on select state. -->
            <div
              ref="dropdown"
              class="b-select__dropdown tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-rounded-md tw-bg-white tw-shadow-lg tw-p-2"
              v-show="isOpen"
            >
              <input
                v-show="isSearchable"
                ref="search"
                v-bind="searchProps"
                v-on="searchEvents"
                :disabled="isDisabled"
                :class="[
                  searchClass,
                  ...[
                    'tw-flex-1',
                    'tw-form-input',
                    'tw-block',
                    'tw-w-full',
                    'tw-min-w-0',
                    'tw-rounded-md',
                    'tw-transition',
                    'tw-duration-150',
                    'tw-ease-in-out',
                    'sm:tw-text-sm',
                    'sm:tw-leading-5',
                    'tw-border',
                    'tw-border-solid',
                    'focus:tw-shadow-none',
                    'tw-mb-2'
                  ]
                ]"
                autocomplete="new-password"
              />
              <ul
                v-show="options.length > 0"
                ref="options"
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                :aria-activedescendant="`b-select__option-${highlightedIndex}`"
                class="b-select__options tw-max-h-60 tw-py-1 tw-text-base tw-leading-6 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm sm:tw-leading-5"
              >
                <slot
                  v-for="(option, index) in options"
                  name="option"
                  v-bind="{
                    option,
                    search: isSearchable ? searchProps.value : undefined,
                    index,
                    displayFn,
                    compareFn,
                    select,
                    highlightedIndex,
                    isTruncated
                  }"
                >
                  <b-select-option
                    v-bind="{
                      value,
                      option,
                      search: isSearchable ? searchProps.value : undefined,
                      index,
                      displayFn,
                      compareFn,
                      select,
                      highlightedIndex,
                      isTruncated
                    }"
                  />
                </slot>

                <!-- More options... -->
              </ul>
              <div
                v-show="loading && options.length === 0"
                class="tw-px-3 tw-text-gray-400 tw-text-center"
              >
                <font-awesome-icon :icon="['fad', 'spinner']" pulse />
                Loading ...
              </div>
              <div
                v-show="!loading && options.length === 0"
                class="tw-px-3 tw-text-gray-400 tw-text-center"
              >
                No results found for ""
              </div>
            </div>
          </div>
        </on-click-outside-provider>
      </label-provider>
    </validation-provider>
  </renderless-select>
</template>

<script>
import { compile } from "vue-template-compiler";
import LabelProvider from "@/components/common/input/label/LabelProvider";
import { ValidationProvider } from "vee-validate";
import defaultSelectProps from "@/components/common/input/select/props.default";
import { OnClickOutsideProvider, RenderlessSelect } from "@buldit/common-fe";
import BSelectSelection from "@/components/common/input/select/BSelectSelection";
import BSelectOption from "@/components/common/input/select/BSelectOption";

export default {
  name: "b-select",
  components: {
    BSelectOption,
    BSelectSelection,
    LabelProvider,
    OnClickOutsideProvider,
    RenderlessSelect,
    ValidationProvider
  },
  props: defaultSelectProps(),
  mounted() {
    // Whenever this component is mounted, sync it's value with the validator, otherwise
    // the validator won't be able to pick up on it if this gets validated before an
    // input or close event occurs.
    this.$refs.validator.syncValue(this.value);
  },
  methods: {
    inspect(value) {
      // eslint-disable-next-line no-unused-vars
      const test = value;
      debugger;
      return value;
    },
    test(value) {
      return compile(value);
    }
  }
};
</script>
