var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"tw-text-center tw-text-xl tw-uppercase tw-font-bold tw-tracking-widest tw-text-primary"},[_vm._v(" Data Provider ")]),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-4 tw-items-start"},_vm._l((_vm.configs),function(ref){
var type = ref.type;
var url = ref.url;
var api = ref.api;
var store = ref.store;
return _c('validation-observer',{key:type,attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationSlotProps){return [_c('div',{staticClass:"tw-bg-white tw-rounded tw-shadow-lg tw-p-8"},[_c('validation-observer-debugger',{attrs:{"validation-props":validationSlotProps}}),_c('data-provider',{attrs:{"store":store,"api":api,"url":url,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var data = ref.data;
var fetch = ref.fetch;
var errors = ref.errors;
return [_c('b-select',{attrs:{"rules":"required","label":("[" + (type.toUpperCase()) + "] Selected Country: " + (JSON.stringify(
                _vm.selectedCountry || 'No selection'
              ))),"loading":loading,"options":data,"error":errors},on:{"open":fetch,"search":function (newSearch) {
                _vm.filters.search = newSearch;
                fetch();
              }},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})]}}],null,true)}),_c('data-provider',{attrs:{"store":store,"api":api,"url":url,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var loading = ref.loading;
              var data = ref.data;
              var fetch = ref.fetch;
              var errors = ref.errors;
return [_c('b-select',{attrs:{"rules":"required","label":("[" + (type.toUpperCase()) + "] Selected Pre-Filled Country: " + (JSON.stringify(
                _vm.selectedPrefilledCountry || 'No selection'
              ))),"loading":loading,"options":data,"error":errors},on:{"open":fetch,"search":function (newSearch) {
                _vm.filters.search = newSearch;
                fetch();
              }},model:{value:(_vm.selectedPrefilledCountry),callback:function ($$v) {_vm.selectedPrefilledCountry=$$v},expression:"selectedPrefilledCountry"}})]}}],null,true)}),_c('data-provider',{attrs:{"store":store,"api":api,"url":url,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var loading = ref.loading;
              var data = ref.data;
              var fetch = ref.fetch;
              var errors = ref.errors;
return [_c('b-select',{attrs:{"multiple":"","rules":"required","label":("[" + (type.toUpperCase()) + "] Selected Multi Country: " + (JSON.stringify(
                _vm.selectedMultiCountry || 'No selection'
              ))),"loading":loading,"options":data,"error":errors},on:{"open":fetch,"search":function (newSearch) {
                _vm.filters.search = newSearch;
                fetch();
              }},model:{value:(_vm.selectedMultiCountry),callback:function ($$v) {_vm.selectedMultiCountry=$$v},expression:"selectedMultiCountry"}})]}}],null,true)}),_c('data-provider',{attrs:{"store":store,"api":api,"url":url,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var loading = ref.loading;
              var data = ref.data;
              var fetch = ref.fetch;
              var errors = ref.errors;
return [_c('b-select',{attrs:{"multiple":"","rules":"required|min_select:2","label":("[" + (type.toUpperCase()) + "] Selected Pre-Filled Multi Country: " + (JSON.stringify(
                _vm.selectedPrefilledMultiCountry || 'No selection'
              ))),"loading":loading,"options":data,"error":errors},on:{"open":fetch,"search":function (newSearch) {
                _vm.filters.search = newSearch;
                fetch();
              }},model:{value:(_vm.selectedPrefilledMultiCountry),callback:function ($$v) {_vm.selectedPrefilledMultiCountry=$$v},expression:"selectedPrefilledMultiCountry"}})]}}],null,true)}),_c('b-btn',{attrs:{"primary":""},on:{"click":function($event){validationSlotProps.validate().then(_vm.onSuccess)}}},[_vm._v("Validate ")]),_c('b-btn',{attrs:{"secondary":""},on:{"click":function($event){return validationSlotProps.reset()}}},[_vm._v("Reset")])],1)]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }