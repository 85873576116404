<template>
  <data-provider
    :store="store"
    :filters="filters"
    v-slot="{ loading, data, fetch, errors }"
  >
    <b-select
      v-bind="{
        // Pass on the props from BCountrySelect
        ...$props,
        // But if props does not contain an error property, instead we pass on the
        // error messages provided by the data provider. This allows the error property
        // to override the data provider errors.
        error: $props.error || errors
      }"
      v-on="{
        ...$listeners,
        open: () => {
          fetch();
          $emit('open');
        },
        search: newSearch => {
          filters.search = newSearch;
          fetch();
          $emit('search', newSearch);
        }
      }"
      :loading="loading"
      :options="data"
    />
  </data-provider>
</template>

<script>
import { DataProvider } from "@buldit/common-fe";
import BSelect from "@/components/common/input/select/BSelect";
import defaultSelectProps from "@/components/common/input/select/props.default";

import apiClient from "@/api/v2/country";
import selectModule from "@/store/templates/common-select";
import { OPTIONS } from "@/store/templates/common-select/getter-types";

export default {
  name: "b-country-select",
  components: { DataProvider, BSelect },
  props: {
    ...defaultSelectProps(),
    displayFn: {
      type: Function,
      default: option => option?.name
    },
    searchFn: {
      type: Function,
      default: (haystack, needle) =>
        haystack.filter(({ name }) => {
          if (needle.trim().length === 0) {
            return true;
          }
          return name.toLowerCase().includes(needle.toLowerCase());
        })
    }
  },
  data: () => ({
    filters: { search: undefined, isActive: true }
  }),
  computed: {
    store() {
      return {
        name: "country-common-select",
        dataGetter: OPTIONS,
        module: selectModule(apiClient)
      };
    }
  }
};
</script>
