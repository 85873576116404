<template>
  <data-provider
    :api="api"
    :filters="filters"
    v-slot="{ loading, data, fetch, errors }"
  >
    <b-select
      v-bind="{
        // Pass on the props from BCitySelect
        ...$props,
        // But if props does not contain an error property, instead we pass on the
        // error messages provided by the data provider. This allows the error property
        // to override the data provider errors.
        error: $props.error || errors
      }"
      v-on="{
        ...$listeners,
        open: () => {
          fetch();
          $emit('open');
        },
        search: newSearch => handleSearchEvent(newSearch)
      }"
      :loading="loading"
      :options="data"
      searchable="async"
    />
  </data-provider>
</template>

<script>
import BSelect from "@/components/common/input/select/BSelect";
import defaultSelectProps from "@/components/common/input/select/props.default";

import apiClient from "@/api/v2/city";
import selectModule from "@/store/templates/common-select";
import { OPTIONS } from "@/store/templates/common-select/getter-types";
import { debounce } from "debounce";

export default {
  name: "b-city-select",
  components: { BSelect },
  props: {
    ...defaultSelectProps(),
    country: {
      type: [Number, Object],
      required: false
    },
    province: {
      type: [Number, Object],
      required: false
    },
    displayFn: {
      type: Function,
      default: option => `${option?.postalCode} - ${option?.name}`
    },
    searchFn: {
      type: Function,
      default: (haystack, needle) =>
        haystack.filter(({ name, postalCode }) => {
          if (needle.trim().length === 0) {
            return true;
          }
          return (
            postalCode.toLowerCase().includes(needle.toLowerCase()) ||
            name.toLowerCase().includes(needle.toLowerCase())
          );
        })
    }
  },
  data() {
    return {
      filters: {
        search: undefined,
        country: Number.isInteger(this.country)
          ? this.country
          : this.country?.id,
        province: Number.isInteger(this.province)
          ? this.province
          : this.province?.id
      },
      debounceTest: null,
      debounceSearchEventHandler: debounce(newerSearch => {
        console.log(`Doing for ${newerSearch}`);
        this.handleSearchEvent(newerSearch);
      }, 500)
    };
  },
  computed: {
    api() {
      return apiClient;
    },
    store() {
      return {
        name: "city-common-select",
        dataGetter: OPTIONS,
        module: selectModule(apiClient)
      };
    }
  },
  methods: {
    onSearchEvent(newSearch) {
      this.debounceSearchEventHandler(newSearch);
    },
    handleSearchEvent(newSearch) {
      console.log("OH YEAH");
      this.filters.search = newSearch;
    }
  }
};
</script>
