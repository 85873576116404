import { SET_IS_LOADING, SET_RESPONSE, SET_THROWABLE } from "./mutation-types";

export default {
  [SET_IS_LOADING](state, isLoading) {
    // If state was loading, now it is not and it was not loaded before, set isLoaded as
    // true.
    if (state.isLoading && !isLoading && !state.isLoaded) {
      state.isLoaded = true;
    }
    state.isLoading = isLoading;
  },
  [SET_RESPONSE](state, response) {
    state.response = response;
  },
  [SET_THROWABLE](state, throwable) {
    state.throwable = throwable;
  }
};
