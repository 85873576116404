<template>
  <li
    :key="index"
    :id="`b-select__option-${index}`"
    @click="select(option)"
    role="option"
    class="tw-cursor-pointer tw-rounded-md tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9"
    :class="{
      'tw-text-gray-900 hover:tw-bg-gray-300': index !== highlightedIndex,
      'tw-text-white tw-bg-secondary hover:tw-bg-primary':
        index === highlightedIndex
    }"
  >
    <!-- Prefix context [OPTIONAL] -->
    <slot
      name="prefix"
      v-bind="{
        value,
        option,
        isTruncated,
        compareFn,
        displayFn: o => getHighlightedHtmlForOption(o),
        highlightFn: highlight
      }"
    />
    <!-- Option content -->
    <slot
      v-bind="{
        value,
        option,
        isTruncated,
        compareFn,
        displayFn: o => getHighlightedHtmlForOption(o),
        highlightFn: highlight
      }"
    >
      <span
        :class="{
          'tw-block': !$scopedSlots.suffix,
          'tw-inline-block': $scopedSlots.suffix,
          'tw-truncate': isTruncated,
          'tw-font-normal': !compareFn(value, option),
          'tw-font-semibold': compareFn(value, option)
        }"
        v-html="getHighlightedHtmlForOption(option)"
      />
    </slot>
    <!-- Suffix context [OPTIONAL] -->
    <slot
      name="suffix"
      v-bind="{
        value,
        option,
        compareFn,
        displayFn: o => getHighlightedHtmlForOption(o),
        highlightFn: highlight,
        isTruncated
      }"
    />
    <!-- Checkmark, only for selected option -->
    <font-awesome-icon
      v-if="compareFn(value, option)"
      :icon="['far', 'check']"
      fixed-width
      class="tw-absolute tw-right-0 tw-flex tw-items-center tw-inset-y-2.5 tw-mr-4"
      :class="{
        'tw-text-indigo-600': index !== highlightedIndex,
        'tw-text-white': index === highlightedIndex
      }"
    />
  </li>
</template>
<script type="text/babel">
import { highlight as highlightHelper } from "@buldit/common-fe";

export default {
  name: "b-select-option",
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: undefined
    },
    option: {
      type: [Object, String, Number],
      required: true
    },
    search: {
      type: String,
      default: undefined
    },
    index: {
      type: Number,
      required: true
    },
    displayFn: {
      type: Function,
      required: true
    },
    compareFn: {
      type: Function,
      required: true
    },
    select: {
      type: Function,
      required: true
    },
    highlightedIndex: {
      type: Number,
      required: true
    },
    isTruncated: {
      type: Boolean,
      default: undefined
    }
  },
  methods: {
    getHighlightedHtmlForOption(option) {
      return this.highlight(this.displayFn(option));
    },
    highlight(htmlString, hightlightClass = "tw-font-bold") {
      if (!this.search || this.search.trim() === "") {
        return htmlString;
      }
      return highlightHelper(htmlString, this.search, hightlightClass);
    }
  }
};
</script>
