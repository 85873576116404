var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-provider',{attrs:{"api":_vm.api,"filters":_vm.filters,"fields":{ company: 'vat_number' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var data = ref.data;
var fetch = ref.fetch;
var errors = ref.errors;
return [_c('b-select',_vm._g(_vm._b({attrs:{"loading":loading,"options":data},scopedSlots:_vm._u([{key:"selection",fn:function(selectionSlotScope){return [_c('b-select-selection',_vm._b({scopedSlots:_vm._u([{key:"suffix",fn:function(ref){
var option = ref.option;
return [(option.state === 'PAUSED')?_c('font-awesome-icon',{staticClass:"tw-ml-1",attrs:{"icon":['far', 'pause-circle'],"size":"lg"}}):_vm._e()]}}],null,true)},'b-select-selection',selectionSlotScope,false))]}},{key:"option",fn:function(itemSlotScope){return [_c('b-select-option',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var option = ref.option;
var isTruncated = ref.isTruncated;
var compareFn = ref.compareFn;
var highlightFn = ref.highlightFn;
return [_c('span',{staticClass:"tw-block",class:{
              'tw-truncate': isTruncated,
              'tw-font-normal': !compareFn(value, option),
              'tw-font-semibold': compareFn(value, option)
            }},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(highlightFn(option.name))}}),(option.state === 'PAUSED')?_c('font-awesome-icon',{staticClass:"tw-ml-1",attrs:{"icon":['far', 'pause-circle'],"size":"lg"}}):_vm._e()],1),_c('div',{domProps:{"innerHTML":_vm._s(highlightFn(option.vatNumber))}})])]}}],null,true)},'b-select-option',itemSlotScope,false))]}}],null,true)},'b-select',Object.assign({}, _vm.$props,
      // But if props does not contain an error property, instead we pass on the
      // error messages provided by the data provider. This allows the error property
      // to override the data provider errors.
      {error: _vm.$props.error || errors}),false),Object.assign({}, _vm.$listeners,
      {open: function () {
        fetch();
        _vm.$emit('open');
      },
      search: function (newSearch) {
        _vm.filters.search = newSearch;
        fetch();
        _vm.$emit('search', newSearch);
      }})))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }