<template>
  <renderless-button
    v-bind="$props"
    v-on="$listeners"
    v-slot="{
      baseClass,
      isDisabled
    }"
  >
    <conditional-simple-wrapper :value="!block" element="span">
      <button
        v-on="$listeners"
        type="button"
        :disabled="isDisabled"
        :class="[
          baseClass,
          typeClass,
          usageClass,
          contextClass,
          sizeClass,
          'tw-rounded-md',
          'tw-shadow-sm'
        ]"
        style="border-width: 1px; border-style: solid;"
      >
        <font-awesome-icon
          v-if="loading"
          :size="
            { xs: 'xs', sm: 'sm', base: undefined, lg: 'lg', xl: '2x' }[size]
          "
          :icon="['fad', 'spinner']"
          pulse
          class="mr-2"
        />
        <slot>Click me</slot>
      </button>
    </conditional-simple-wrapper>
  </renderless-button>
</template>

<script>
import defaultButtonProps from "@/components/common/input/button/props.default";
import ConditionalSimpleWrapper from "@/components/common/ConditionalSimpleWrapper";
import { RenderlessButton } from "@buldit/common-fe";

export default {
  name: "b-btn",
  components: {
    ConditionalSimpleWrapper,
    RenderlessButton
  },
  props: defaultButtonProps(),
  computed: {
    size() {
      if (this.xl) {
        return "xl";
      }
      if (this.lg) {
        return "lg";
      }
      if (this.sm) {
        return "sm";
      }
      if (this.xs) {
        return "xs";
      }
      return "base";
    },
    typeClass() {
      if (this.outline) {
        return `${this.baseClass}--outline`;
      }

      if (this.flat) {
        return `${this.baseClass}--flat`;
      }

      return undefined;
    },
    usageClass() {
      if (this.block) {
        return `${this.baseClass}--block`;
      }
      return undefined;
    },
    contextClass() {
      if (this.error) {
        return `${this.baseClass}--error`;
      }
      if (this.warning) {
        return `${this.baseClass}--warning`;
      }
      if (this.success) {
        return `${this.baseClass}--success`;
      }
      if (this.info) {
        return `${this.baseClass}--info`;
      }
      if (this.primary) {
        return `${this.baseClass}--primary`;
      }
      if (this.secondary) {
        return `${this.baseClass}--secondary`;
      }
      if (this.accent) {
        return `${this.baseClass}--accent`;
      }
      return undefined;
    },
    sizeClass() {
      return `${this.baseClass}--${this.size}`;
    }
  }
};
</script>
