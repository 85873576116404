export default () => ({
  // Core state

  value: {
    type: [Object, Array, String, Number],
    default: undefined
  },
  options: {
    type: Array,
    default: () => []
  },
  searchable: {
    type: [Object, String, Boolean],
    default: undefined
  },
  multiple: {
    type: Boolean,
    default: undefined
  },

  // Input state

  loading: {
    type: Boolean,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: undefined
  },
  readonly: {
    type: Boolean,
    default: undefined
  },

  // Validation state

  rules: {
    type: [String, Array],
    default: undefined
  },
  required: {
    type: Boolean,
    default: undefined
  },
  min: {
    type: Number,
    default: undefined
  },
  max: {
    type: Number,
    default: undefined
  },

  // Context messages

  error: {
    type: [String, Array, Object],
    default: undefined
  },
  warning: {
    type: [String, Array, Object],
    default: undefined
  },
  info: {
    type: [String, Array, Object],
    default: undefined
  },
  success: {
    type: [String, Array, Object],
    default: undefined
  },

  // Utility function

  searchFn: {
    type: Function,
    default: undefined
  },
  compareFn: {
    type: Function,
    default: (option, other) => {
      if (!option || !other) {
        return false;
      }

      return (
        option === other ||
        (option.id && other.id && option.id === other.id) ||
        (option.value && other.value && option.value === other.value)
      );
    }
  },
  displayFn: {
    type: Function,
    default: option => {
      if (typeof option === "string") {
        return option;
      }

      if (Array.isArray(option)) {
        return option.join(", ");
      }

      return option?.label || option?.name || option?.text;
    }
  },

  // Label

  label: {
    type: String,
    default: undefined
  },
  inline: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: undefined
  },

  // Layout

  truncated: {
    type: Boolean,
    default: undefined
  },

  // Class overrides

  baseClass: {
    type: String,
    default: "b-select"
  },
  searchClass: {
    type: String,
    default: "b-select__search"
  },
  btnClass: {
    type: String,
    default: "b-select__btn"
  },
  dropdownClass: {
    type: String,
    default: "b-select__dropdown"
  },
  optionsClass: {
    type: String,
    default: "b-select__options"
  }
});
