<template>
  <div>
    <h1
      class="tw-text-center tw-text-xl tw-uppercase tw-font-bold tw-tracking-widest tw-text-primary"
    >
      Data Provider
    </h1>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-items-start">
      <validation-observer
        v-for="{ type, url, api, store } in configs"
        :key="type"
        v-slot="validationSlotProps"
        slim
      >
        <div class="tw-bg-white tw-rounded tw-shadow-lg tw-p-8">
          <validation-observer-debugger
            :validation-props="validationSlotProps"
          />

          <data-provider
            :store="store"
            :api="api"
            :url="url"
            :filters="filters"
            v-slot="{ loading, data, fetch, errors }"
          >
            <b-select
              v-model="selectedCountry"
              rules="required"
              :label="
                `[${type.toUpperCase()}] Selected Country: ${JSON.stringify(
                  selectedCountry || 'No selection'
                )}`
              "
              @open="fetch"
              @search="
                newSearch => {
                  filters.search = newSearch;
                  fetch();
                }
              "
              :loading="loading"
              :options="data"
              :error="errors"
            />
          </data-provider>
          <data-provider
            :store="store"
            :api="api"
            :url="url"
            :filters="filters"
            v-slot="{ loading, data, fetch, errors }"
          >
            <b-select
              v-model="selectedPrefilledCountry"
              rules="required"
              :label="
                `[${type.toUpperCase()}] Selected Pre-Filled Country: ${JSON.stringify(
                  selectedPrefilledCountry || 'No selection'
                )}`
              "
              @open="fetch"
              @search="
                newSearch => {
                  filters.search = newSearch;
                  fetch();
                }
              "
              :loading="loading"
              :options="data"
              :error="errors"
            />
          </data-provider>
          <data-provider
            :store="store"
            :api="api"
            :url="url"
            :filters="filters"
            v-slot="{ loading, data, fetch, errors }"
          >
            <b-select
              v-model="selectedMultiCountry"
              multiple
              rules="required"
              :label="
                `[${type.toUpperCase()}] Selected Multi Country: ${JSON.stringify(
                  selectedMultiCountry || 'No selection'
                )}`
              "
              @open="fetch"
              @search="
                newSearch => {
                  filters.search = newSearch;
                  fetch();
                }
              "
              :loading="loading"
              :options="data"
              :error="errors"
            />
          </data-provider>
          <data-provider
            :store="store"
            :api="api"
            :url="url"
            :filters="filters"
            v-slot="{ loading, data, fetch, errors }"
          >
            <b-select
              v-model="selectedPrefilledMultiCountry"
              multiple
              rules="required|min_select:2"
              :label="
                `[${type.toUpperCase()}] Selected Pre-Filled Multi Country: ${JSON.stringify(
                  selectedPrefilledMultiCountry || 'No selection'
                )}`
              "
              @open="fetch"
              @search="
                newSearch => {
                  filters.search = newSearch;
                  fetch();
                }
              "
              :loading="loading"
              :options="data"
              :error="errors"
            />
          </data-provider>

          <b-btn primary @click="validationSlotProps.validate().then(onSuccess)"
            >Validate
          </b-btn>
          <b-btn secondary @click="validationSlotProps.reset()">Reset</b-btn>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script type="text/babel">
import BSelect from "@/components/common/input/select/BSelect";
import BBtn from "@/components/common/input/button/BBtn";
import { ValidationObserver } from "vee-validate";
import ValidationObserverDebugger from "@/components/common/input/validation/ValidationObserverDebugger";
import { OPTIONS } from "@/store/templates/common-select/getter-types";
import selectModule from "@/store/templates/common-select";
import apiClient from "@/api/v2/country";
import { DataProvider } from "@buldit/common-fe";

export default {
  name: "data-provider-development",
  components: {
    ValidationObserver,
    ValidationObserverDebugger,
    DataProvider,
    BSelect,
    BBtn
  },
  data: () => ({
    filters: { search: undefined, isActive: true },
    selectedCountry: undefined,
    selectedPrefilledCountry: { id: 4, name: "Duitsland" },
    selectedMultiCountry: undefined,
    selectedPrefilledMultiCountry: [{ id: 4, name: "Duitsland" }]
  }),
  computed: {
    store() {
      return {
        name: "country-common-select",
        dataGetter: OPTIONS,
        module: selectModule(apiClient)
      };
    },
    configs() {
      return [
        {
          type: "url",
          url: "api/v2/countries"
        },
        {
          type: "api",
          api: apiClient
        },
        {
          type: "store",
          store: this.store
        }
      ];
    }
  },
  methods: {
    onSuccess() {
      console.log("success");
    }
  }
};
</script>
