<template>
  <div>
    <h1>Force layout</h1>
    <template v-for="(layout, index) in computedLayouts">
      <b-btn
        :warning="index === currentlyForcedLayout"
        @click="toggle(index)"
        :key="index"
        >{{ index }}
      </b-btn>
    </template>
  </div>
</template>

<script type="text/babel">
import BBtn from "@/components/common/input/button/BBtn";
import { loadComponentsFromContext } from "@buldit/common-fe";

const webpackContext = require.context("@/layouts", false, /.*\.vue$/);
const layouts = loadComponentsFromContext(webpackContext);

export default {
  name: "development-view",
  components: { BBtn },
  computed: {
    computedLayouts() {
      return layouts;
    },
    layoutManager() {
      const rootComponent = this.$root.$refs.root;
      return rootComponent.$refs["layout-manager"];
    },
    currentlyForcedLayout() {
      return this.layoutManager?.getForcedLayout();
    }
  },
  methods: {
    toggle(layout) {
      this.layoutManager?.forceLayout(
        this.currentlyForcedLayout === layout ? undefined : layout
      );
    }
  }
};
</script>
