var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-my-2 tw-pb-1 tw-px-2 tw-rounded tw-text-center",class:("tw-bg-" + _vm.contextColor + "-100")},[_c('span',{staticClass:"tw-text-xs tw-font-bold tw-uppercase",class:("tw-text-" + _vm.contextColor + "-400")},[_vm._v("Validation Observer State")]),_c('div',{staticClass:"tw-grid tw-grid-cols-7 tw-gap-4 tw-items-start"},_vm._l(([
        'dirty',
        'pristine',
        'touched',
        'untouched',
        'pending',
        'valid',
        'invalid'
      ]),function(state){return _c('div',{key:state},[_c('div',{staticClass:"tw-text-xs tw-uppercase",class:{
          'tw-font-bold': _vm.validationProps[state] !== _vm.defaultFlags[state]
        }},[_vm._v(" "+_vm._s(state)+" ")]),_c('div',{class:_vm.validationProps[state] ? 'tw-text-green-500' : 'tw-text-red-500'},[_c('font-awesome-icon',{attrs:{"icon":_vm.validationProps[state]
              ? ['fad', 'check-square']
              : ['fad', 'times-square']}})],1)])}),0),(_vm.errors.length > 0)?[_c('ul',{staticClass:"tw-text-red-500"},_vm._l((_vm.errors),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }