var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{key:_vm.index,staticClass:"tw-cursor-pointer tw-rounded-md tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9",class:{
    'tw-text-gray-900 hover:tw-bg-gray-300': _vm.index !== _vm.highlightedIndex,
    'tw-text-white tw-bg-secondary hover:tw-bg-primary':
      _vm.index === _vm.highlightedIndex
  },attrs:{"id":("b-select__option-" + _vm.index),"role":"option"},on:{"click":function($event){return _vm.select(_vm.option)}}},[_vm._t("prefix",null,null,{
      value: _vm.value,
      option: _vm.option,
      isTruncated: _vm.isTruncated,
      compareFn: _vm.compareFn,
      displayFn: function (o) { return _vm.getHighlightedHtmlForOption(o); },
      highlightFn: _vm.highlight
    }),_vm._t("default",[_c('span',{class:{
        'tw-block': !_vm.$scopedSlots.suffix,
        'tw-inline-block': _vm.$scopedSlots.suffix,
        'tw-truncate': _vm.isTruncated,
        'tw-font-normal': !_vm.compareFn(_vm.value, _vm.option),
        'tw-font-semibold': _vm.compareFn(_vm.value, _vm.option)
      },domProps:{"innerHTML":_vm._s(_vm.getHighlightedHtmlForOption(_vm.option))}})],null,{
      value: _vm.value,
      option: _vm.option,
      isTruncated: _vm.isTruncated,
      compareFn: _vm.compareFn,
      displayFn: function (o) { return _vm.getHighlightedHtmlForOption(o); },
      highlightFn: _vm.highlight
    }),_vm._t("suffix",null,null,{
      value: _vm.value,
      option: _vm.option,
      compareFn: _vm.compareFn,
      displayFn: function (o) { return _vm.getHighlightedHtmlForOption(o); },
      highlightFn: _vm.highlight,
      isTruncated: _vm.isTruncated
    }),(_vm.compareFn(_vm.value, _vm.option))?_c('font-awesome-icon',{staticClass:"tw-absolute tw-right-0 tw-flex tw-items-center tw-inset-y-2.5 tw-mr-4",class:{
      'tw-text-indigo-600': _vm.index !== _vm.highlightedIndex,
      'tw-text-white': _vm.index === _vm.highlightedIndex
    },attrs:{"icon":['far', 'check'],"fixed-width":""}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }