<script>
import InlineLabel from "@/components/common/input/label/InlineLabel";
import StackedLabel from "@/components/common/input/label/StackedLabel";
import defaultLabelProps from "@/components/common/input/label/props.default";

export default {
  name: "label-provider",
  functional: true,
  props: {
    ...defaultLabelProps(),
    inline: {
      type: Boolean,
      default: undefined
    }
  },
  render: function(createElement, { props, data, children }) {
    function appropriateLabelComponent() {
      const label = props.label;
      const inline = props.inline;

      if (!label) {
        return undefined;
      }

      return inline ? InlineLabel : StackedLabel;
    }

    const component = appropriateLabelComponent();

    if (!component) {
      return children;
    }

    return createElement(
      component,
      {
        ...data,
        props: {
          label: props.label,
          name: props.name,
          contextMessages: props.contextMessages,
          hint: props.hint
        }
      },
      children
    );
  }
};
</script>
