var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-provider',{attrs:{"api":_vm.api,"filters":_vm.filters,"fields":{
    question: 'questionable,isRoot,inheritedName,inheritedLabel'
  },"include":[
      'questionable',
      'label',
      'parentQuestion.label',
      'parentQuestion.parentQuestion.label',
      'parentQuestion.parentQuestion.parentQuestion.label',
      'parentQuestion.parentQuestion.parentQuestion.parentQuestion.label',
      'parentQuestion.parentQuestion.parentQuestion.parentQuestion'
    ].join()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var loading = ref.loading;
    var data = ref.data;
    var fetch = ref.fetch;
    var errors = ref.errors;
return [_c('b-select',_vm._g(_vm._b({attrs:{"loading":loading,"options":data},scopedSlots:_vm._u([{key:"selection",fn:function(selectionSlotScope){return [_c('b-select-selection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var option = ref.option;
return [_vm._v(" "+_vm._s(_vm.getLabelFromOption(option))+" ")]}},{key:"suffix",fn:function(ref){
    var option = ref.option;
return [(option.isRoot)?_c('font-awesome-icon',{staticClass:"tw-text-gray-500",attrs:{"icon":['far', 'square-root'],"fixed-width":"","size":"sm"}}):_vm._e(),(_vm.doesOptionHaveASubjectQuestionable(option))?_c('b-icon-subject',{staticClass:"tw-text-gray-500",attrs:{"type":option.questionable.type,"fixed-width":"","size":"sm"}}):_vm._e()]}}],null,true)},'b-select-selection',selectionSlotScope,false))]}},{key:"option",fn:function(itemSlotScope){return [_c('b-select-option',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var value = ref.value;
    var option = ref.option;
    var compareFn = ref.compareFn;
    var highlightFn = ref.highlightFn;
    var isTruncated = ref.isTruncated;
return [_c('span',{staticClass:"tw-block",class:{
              'tw-truncate': isTruncated,
              'tw-font-normal': !compareFn(value, option),
              'tw-font-semibold': compareFn(value, option)
            }},[_c('div',{domProps:{"innerHTML":_vm._s(highlightFn(_vm.getLabelFromOption(option)))}}),_c('div',[_c('font-awesome-icon',{staticClass:"tw-mr-1",attrs:{"icon":[
                  'far',
                  option.isRoot ? 'square-root' : 'square-root-alt'
                ],"fixed-width":"","size":"sm"}}),_c('span',{staticClass:"tw-text-gray-500 tw-uppercase tw-font-bold tw-mr-1",domProps:{"innerHTML":_vm._s(option.isRoot ? 'Root' : 'Inherited')}}),(
                  option.questionable &&
                    _vm.doesOptionHaveASubjectQuestionable(option)
                )?[_c('b-icon-subject',{staticClass:"tw-mr-0.5",attrs:{"type":option.questionable.type,"fixed-width":"","size":"sm"}}),_c('span',{staticClass:"tw-text-gray-500",domProps:{"innerHTML":_vm._s(highlightFn(option.questionable.name))}})]:(option.questionable)?_c('span',{staticClass:"tw-text-gray-500"},[_vm._v(" Unexpected questionable: "+_vm._s(option.questionable.id)+" ")]):_c('span',{staticClass:"tw-text-gray-500"})],2)])]}}],null,true)},'b-select-option',itemSlotScope,false))]}}],null,true)},'b-select',Object.assign({}, _vm.$props,
      // But if props does not contain an error property, instead we pass on the
      // error messages provided by the data provider. This allows the error property
      // to override the data provider errors.
      {error: _vm.$props.error || errors}),false),Object.assign({}, _vm.$listeners,
      {open: function () {
        fetch();
        _vm.$emit('open');
      },
      search: function (newSearch) {
        _vm.filters.search = newSearch;
        fetch();
        _vm.$emit('search', newSearch);
      }})))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }