<template>
  <font-awesome-icon
    :icon="[
      internalType === 'JOB' ? 'far' : 'fas',
      internalType === 'SECTOR' ? 'circle' : 'dot-circle'
    ]"
    :size="size"
    :fixed-width="fixedWidth"
  />
</template>

<script type="text/babel">
export default {
  name: "b-icon-subject",
  props: {
    type: {
      type: String,
      default: undefined,
      validator: value =>
        ["SECTOR", "SUBSECTOR", "JOB"].indexOf(value.toUpperCase()) > -1
    },
    sector: {
      type: Boolean,
      default: undefined
    },
    subsector: {
      type: Boolean,
      default: undefined
    },
    job: {
      type: Boolean,
      default: undefined
    },
    size: {
      type: String,
      default: undefined,
      validator: value =>
        [
          "lg",
          "xs",
          "sm",
          "1x",
          "2x",
          "3x",
          "4x",
          "5x",
          "6x",
          "7x",
          "8x",
          "9x",
          "10x"
        ].indexOf(value) > -1
    },
    fixedWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalType() {
      if (this.type) return this.type.toUpperCase();
      if (this.sector) return "SECTOR";
      if (this.subsector) return "SUBSECTOR";
      if (this.job) return "JOB";
      throw Error("BIconSubject should be either sector, subsector or job.");
    }
  }
};
</script>
