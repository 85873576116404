export default () => ({
  // Core state

  // Input state
  loading: {
    type: Boolean,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: undefined
  },

  // Layout state
  outline: {
    type: Boolean,
    default: undefined
  },
  flat: {
    type: Boolean,
    default: undefined
  },
  block: {
    type: Boolean,
    default: undefined
  },
  xs: {
    type: Boolean,
    default: undefined
  },
  sm: {
    type: Boolean,
    default: undefined
  },
  lg: {
    type: Boolean,
    default: undefined
  },
  xl: {
    type: Boolean,
    default: undefined
  },

  // Context state
  primary: {
    type: Boolean,
    default: undefined
  },
  secondary: {
    type: Boolean,
    default: undefined
  },
  accent: {
    type: Boolean,
    default: undefined
  },
  error: {
    type: Boolean,
    default: undefined
  },
  warning: {
    type: Boolean,
    default: undefined
  },
  success: {
    type: Boolean,
    default: undefined
  },
  info: {
    type: Boolean,
    default: undefined
  },

  // Class overrides
  baseClass: {
    type: String,
    default: "b-btn"
  }
});
