<template>
  <span class="tw-block" :class="{ 'tw-truncate': isTruncated }">
    <span
      v-if="!hasValue"
      class="b-select__placeholder"
      v-html="placeholder || '&nbsp;'"
    ></span>
    <template v-else-if="isMultiple">
      <template v-for="(singleValue, index) in value">
        <span v-if="index !== 0" :key="`${index}-comma`">,&nbsp;</span>
        <slot name="prefix" v-bind="{ option: singleValue, isMultiple }" />
        <slot v-bind="{ option: singleValue, isMultiple }">
          <span :key="index" v-html="displayFn(singleValue)" />
        </slot>
        <slot name="suffix" v-bind="{ option: singleValue, isMultiple }" />
      </template>
    </template>
    <template v-else>
      <slot name="prefix" v-bind="{ option: value, isMultiple }" />
      <slot v-bind="{ option: value, isMultiple }">
        <span v-html="displayFn(value)" />
      </slot>
      <slot name="suffix" v-bind="{ option: value, isMultiple }" />
    </template>
  </span>
</template>
<script type="text/babel">
export default {
  name: "b-select-selection",
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: undefined
    },
    placeholder: {
      type: String,
      required: false
    },
    hasValue: {
      type: Boolean,
      default: undefined
    },
    isMultiple: {
      type: Boolean,
      default: undefined
    },
    isTruncated: {
      type: Boolean,
      default: undefined
    },
    displayFn: {
      type: Function,
      required: true
    }
  }
};
</script>
