<template>
  <div>
    <div class="tw-flex tw-justify-between" v-if="hint">
      <label
        :for="name"
        class="tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700"
        >{{ label }}</label
      >
      <span
        class="tw-text-sm tw-leading-5 tw-text-gray-500"
        id="email-optional"
        >{{ hint }}</span
      >
    </div>
    <label
      v-else
      :for="name"
      class="tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700"
      >{{ label }}</label
    >
    <div class="tw-mt-1 tw-mb-2 tw-relative tw-rounded-md tw-shadow-sm">
      <slot />
      <!--        <input -->
      <!--            id="email" -->
      <!--            class="tw-form-input tw-block tw-w-full tw-pr-10 tw-border-red-300 tw-text-red-900 tw-placeholder-red-300 focus:tw-border-red-300 focus:tw-shadow-outline-red sm:tw-text-sm sm:tw-leading-5"-->
      <!--            placeholder="you@example.com"-->
      <!--            value="adamwathan"-->
      <!--            aria-invalid="true"-->
      <!--            aria-describedby="email-error"-->
      <!--        >-->
    </div>
    <div :id="`${name}-describedby`" :class="{ 'tw-pb-2': !!contextMessages }">
      <template v-for="(messages, type) in contextMessages">
        <p
          v-for="(message, innerIndex) in messages"
          :key="`${type}-${innerIndex}`"
          class="tw-text-sm"
          :class="{
            'tw-text-error': type === 'errors',
            'tw-text-warning': type === 'warnings',
            'tw-text-success': type === 'successes',
            'tw-text-info': type === 'infos'
          }"
          style="margin-bottom: 0;"
        >
          {{ message }}
        </p>
      </template>
    </div>
  </div>
</template>
<script>
import defaultLabelProps from "@/components/common/input/label/props.default";

export default {
  name: "stacked-label",
  props: defaultLabelProps()
};
</script>
