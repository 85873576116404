import { FETCH } from "./action-types";
import {
  SET_IS_LOADING,
  SET_RESPONSE,
  SET_THROWABLE
} from "@/store/templates/common-select/mutation-types";
import {
  IS_ERROR,
  IS_LOADED
} from "@/store/templates/common-select/getter-types";

export default apiModule => ({
  async [FETCH](
    { commit, getters },
    {
      force = false,
      filters = undefined,
      fields = undefined,
      include = undefined
    } = {}
  ) {
    // If already loaded, dont fetch again unless either the force option is true or
    // there was an exception when loading last time, and exit early accordingly.
    if (getters[IS_LOADED] && !(force || getters[IS_ERROR])) {
      return;
    }

    try {
      commit(SET_THROWABLE, undefined);
      commit(SET_IS_LOADING, true);
      commit(
        SET_RESPONSE,
        await apiModule.findAll({
          filters,
          fields,
          include
        })
      );
    } catch (throwable) {
      commit(SET_THROWABLE, throwable);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  }
});
