<script>
export default {
  name: "conditional-simple-wrapper",
  functional: true,
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    element: {
      type: String,
      default: "div"
    }
  },
  render: function(createElement, { props, data, children }) {
    if (props.value) {
      return createElement(props.element, data, children);
    }

    return children;
  }
};
</script>
