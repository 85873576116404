var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('renderless-button',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var baseClass = ref.baseClass;
  var isDisabled = ref.isDisabled;
return [_c('conditional-simple-wrapper',{attrs:{"value":!_vm.block,"element":"span"}},[_c('button',_vm._g({class:[
        baseClass,
        _vm.typeClass,
        _vm.usageClass,
        _vm.contextClass,
        _vm.sizeClass,
        'tw-rounded-md',
        'tw-shadow-sm'
      ],staticStyle:{"border-width":"1px","border-style":"solid"},attrs:{"type":"button","disabled":isDisabled}},_vm.$listeners),[(_vm.loading)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"size":{ xs: 'xs', sm: 'sm', base: undefined, lg: 'lg', xl: '2x' }[_vm.size],"icon":['fad', 'spinner'],"pulse":""}}):_vm._e(),_vm._t("default",[_vm._v("Click me")])],2)])]}}],null,true)},'renderless-button',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }