<template>
  <data-provider
    :store="store"
    :filters="filters"
    v-slot="{ loading, data, fetch, errors }"
  >
    <b-select
      v-bind="{
        // Pass on the props from BSubjectSelect
        ...$props,
        // But if props does not contain an error property, instead we pass on the
        // error messages provided by the data provider. This allows the error property
        // to override the data provider errors.
        error: $props.error || errors
      }"
      v-on="{
        ...$listeners,
        open: () => {
          fetch();
          $emit('open');
        },
        search: newSearch => {
          filters.search = newSearch;
          fetch();
          $emit('search', newSearch);
        }
      }"
      :loading="loading"
      :options="data"
    >
      <!-- Scoped slot 'selection' -->
      <template v-slot:selection="selectionSlotScope">
        <b-select-selection v-bind="selectionSlotScope">
          <template v-slot:suffix="{ option }">
            <b-icon-subject :type="option.type" size="sm" class="tw-ml-1" />
          </template>
        </b-select-selection>
      </template>

      <!-- Scoped slot 'option' -->
      <template v-slot:option="itemSlotScope">
        <b-select-option v-bind="itemSlotScope">
          <template v-slot:suffix="{ option }">
            <b-icon-subject :type="option.type" size="sm" class="tw-ml-1" />
          </template>
        </b-select-option>
      </template>
    </b-select>
  </data-provider>
</template>

<script>
import BSelect from "@/components/common/input/select/BSelect";
import defaultSelectProps from "@/components/common/input/select/props.default";

import apiClient from "@/api/v2/subject";
import { OPTIONS } from "@/store/templates/common-select/getter-types";
import selectModule from "@/store/templates/common-select";
import BSelectSelection from "@/components/common/input/select/BSelectSelection";
import BIconSubject from "@/components/common/icon/BIconSubject";
import BSelectOption from "@/components/common/input/select/BSelectOption";

export default {
  name: "b-subject-select",
  components: { BSelectOption, BIconSubject, BSelectSelection, BSelect },
  props: {
    ...defaultSelectProps(),
    displayFn: {
      type: Function,
      default: option => option?.name
    },
    searchFn: {
      type: Function,
      default: (haystack, needle) =>
        haystack.filter(({ name }) => {
          if (needle.trim().length === 0) {
            return true;
          }
          return name.toLowerCase().includes(needle.toLowerCase());
        })
    }
  },
  data: () => ({
    filters: { search: undefined }
  }),
  computed: {
    store() {
      return {
        name: "subject-common-select",
        dataGetter: OPTIONS,
        module: selectModule(apiClient)
      };
    }
  }
};
</script>
