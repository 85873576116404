<template>
  <validation-observer v-slot="validationSlotProps" slim>
    <div>
      <validation-observer-debugger :validation-props="validationSlotProps" />
      <div class="tw-grid tw-grid-cols-4 tw-gap-x-4 tw-items-start">
        <b-country-select
          v-model="country"
          searchable
          :label="getLabel('Country', country)"
        />
        <b-country-select
          v-model="countries"
          searchable
          multiple
          :label="getLabel('Countries', countries)"
        />
        <b-country-select
          v-model="countryPrefill"
          searchable
          :label="getLabel('Country Prefill', countryPrefill)"
        />
        <b-country-select
          v-model="countryPrefill"
          :country="{ id: 1 }"
          disabled
          :label="getLabel('Country Disabled', countryPrefill)"
        />
        <b-provinces-select
          v-model="province"
          :country="{ id: 1 }"
          searchable
          :label="getLabel('Province', province)"
        />
        <b-provinces-select
          v-model="provinces"
          :country="{ id: 1 }"
          searchable
          multiple
          :label="getLabel('Provinces', provinces)"
        />
        <b-provinces-select
          v-model="provincePrefill"
          :country="{ id: 1 }"
          searchable
          :label="getLabel('Province Prefill', provincePrefill)"
        />
        <b-provinces-select
          v-model="provincePrefill"
          :country="{ id: 1 }"
          disabled
          :label="getLabel('Province Disabled', provincePrefill)"
        />
        <b-city-select
          v-model="city"
          :country="1"
          searchable
          :label="getLabel('City', city)"
        />
        <b-city-select
          v-model="cities"
          :country="1"
          searchable
          multiple
          :label="getLabel('Cities', cities)"
        />
        <b-city-select
          v-model="cityPrefill"
          :country="1"
          searchable
          :label="getLabel('City Prefill', cityPrefill)"
        />
        <b-city-select
          v-model="cityPrefill"
          :country="1"
          disabled
          :label="getLabel('City Disabled', cityPrefill)"
        />
        <b-language-select
          v-model="language"
          searchable
          :label="getLabel('Language', language)"
        />
        <b-language-select
          v-model="languages"
          searchable
          multiple
          :label="getLabel('Languages', languages)"
        />
        <b-language-select
          v-model="languagePrefill"
          searchable
          :label="getLabel('Language Prefill', languagePrefill)"
        />
        <b-language-select
          v-model="languagePrefill"
          disabled
          :label="getLabel('Language Disabled', languagePrefill)"
        />
        <b-locale-select
          v-model="locale"
          searchable
          :label="getLabel('Locale', locale)"
        />
        <b-locale-select
          v-model="locales"
          searchable
          multiple
          :label="getLabel('Locales', locales)"
        />
        <b-locale-select
          v-model="localePrefill"
          searchable
          :label="getLabel('Locale Prefill', localePrefill)"
        />
        <b-locale-select
          v-model="localePrefill"
          disabled
          :label="getLabel('Locale Disabled', localePrefill)"
        />
        <b-affiliate-select
          v-model="affiliate"
          :label="getLabel('Affiliate', affiliate)"
        />
        <b-affiliate-select
          v-model="affiliates"
          multiple
          :label="getLabel('Affiliates', affiliates)"
        />
        <b-affiliate-select
          v-model="affiliatePrefill"
          :label="getLabel('Affiliate Prefill', affiliatePrefill)"
        />
        <b-affiliate-select
          v-model="affiliatePrefill"
          disabled
          :label="getLabel('Affiliate Disabled', affiliatePrefill)"
        />
        <b-subject-select
          v-model="subject"
          :label="getLabel('Subject', subject)"
        />
        <b-subject-select
          v-model="subjects"
          multiple
          :label="getLabel('Subject', subjects)"
        />
        <b-subject-select
          v-model="subjectPrefill"
          :label="getLabel('Subject Prefill', subjectPrefill)"
        />
        <b-subject-select
          v-model="subjectPrefill"
          disabled
          :label="getLabel('Subject Disabled', subjectPrefill)"
        />
        <b-question-select
          v-model="question"
          :label="getLabel('Question', question)"
        />
        <b-question-select
          v-model="questions"
          multiple
          :label="getLabel('Question', questions)"
        />
        <b-question-select
          v-model="questionPrefill"
          :label="getLabel('Question Prefill', questionPrefill)"
        />
        <b-question-select
          v-model="questionPrefill"
          disabled
          :label="getLabel('Question Disabled', questionPrefill)"
        />
      </div>
      <b-btn primary @click="validationSlotProps.validate().then(onSuccess)"
        >Validate
      </b-btn>
      <b-btn secondary @click="validationSlotProps.reset()">Reset</b-btn>
    </div>
  </validation-observer>
</template>

<script type="text/babel">
import { ValidationObserver } from "vee-validate";
import ValidationObserverDebugger from "@/components/common/input/validation/ValidationObserverDebugger";
import BCountrySelect from "@/components/common/input/select/BCountrySelect";
import BProvincesSelect from "@/components/common/input/select/BProvinceSelect";
import BCitySelect from "@/components/common/input/select/BCitySelect";
import BBtn from "@/components/common/input/button/BBtn";
import BLocaleSelect from "@/components/common/input/select/BLocaleSelect";
import BLanguageSelect from "@/components/common/input/select/BLanguageSelect";
import BAffiliateSelect from "@/components/common/input/select/BAffiliateSelect";
import BSubjectSelect from "@/components/common/input/select/BSubjectSelect";
import BQuestionSelect from "@/components/common/input/select/BQuestionSelect";

export default {
  name: "b-select-development",
  components: {
    BQuestionSelect,
    BSubjectSelect,
    BAffiliateSelect,
    ValidationObserver,
    ValidationObserverDebugger,
    BCountrySelect,
    BProvincesSelect,
    BCitySelect,
    BLanguageSelect,
    BLocaleSelect,
    BBtn
  },
  data: () => ({
    country: undefined,
    countries: undefined,
    countryPrefill: { id: 1, name: "Belgie" },
    province: undefined,
    provinces: undefined,
    provincePrefill: { id: 1, name: "Antwerpen" },
    city: undefined,
    cities: undefined,
    cityPrefill: { id: 1, name: "Brussel", postalCode: "1000" },
    language: undefined,
    languages: undefined,
    languagePrefill: { id: 1, name: "Nederlands" },
    locale: undefined,
    locales: undefined,
    localePrefill: { id: 1, name: "Engels van de UK" },
    affiliate: undefined,
    affiliates: undefined,
    affiliatePrefill: { id: 1, name: "Evotis", state: "PAUSED" },
    subject: undefined,
    subjects: undefined,
    subjectPrefill: { id: 1, name: "Beveiliging", type: "SECTOR" },
    question: undefined,
    questions: undefined,
    questionPrefill: {
      id: 1,
      name: "default_description",
      type: "TEXTAREA",
      isRoot: true
    }
  }),
  methods: {
    onSuccess() {
      alert("Validated!");
    },
    getLabel(label, value) {
      return `${label}: ${JSON.stringify(value || "No selection")}`;
    }
  }
};
</script>
