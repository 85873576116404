<template>
  <div>
    <h1
      class="tw-text-center tw-text-xl tw-uppercase tw-font-bold tw-tracking-widest tw-text-primary"
    >
      Buttons
    </h1>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-place-items-center">
      <div
        class="tw-w-full tw-h-full tw-bg-white tw-rounded tw-shadow-lg tw-p-8"
      >
        <h2
          class="tw-text-center tw-text-lg tw-uppercase tw-font-bold tw-tracking-wider tw-text-secondary tw-py-3"
        >
          Context
        </h2>

        <div class="tw-grid tw-grid-cols-8 tw-gap-4 tw-place-items-center">
          <template
            v-for="config in [
              'plain',
              'block',
              'outline',
              'outline-block',
              'flat',
              'flat-block'
            ]"
          >
            <template
              v-for="context in [
                'none',
                'primary',
                'secondary',
                'accent',
                'error',
                'warning',
                'success',
                'info'
              ]"
            >
              <b-btn
                :key="`default-${context}-${config}`"
                :block="config.includes('block')"
                :outline="config.includes('outline')"
                :flat="config.includes('flat')"
                :primary="context === 'primary'"
                :secondary="context === 'secondary'"
                :accent="context === 'accent'"
                :error="context === 'error'"
                :warning="context === 'warning'"
                :success="context === 'success'"
                :info="context === 'info'"
                >{{ config === "plain" ? context : config }}
              </b-btn>
            </template>
          </template>
        </div>

        <h2
          class="tw-text-center tw-text-lg tw-uppercase tw-font-bold tw-tracking-wider tw-text-secondary tw-py-3"
        >
          Usage
        </h2>

        <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-place-items-center">
          <b-btn>Default</b-btn>
          <b-btn block>Block</b-btn>
          <div>
            <b-btn>Default</b-btn>
            <b-btn primary>Default</b-btn>
            <b-btn accent>Default</b-btn>
          </div>
          <div>
            <b-btn block>Block</b-btn>
            <b-btn primary block>Block</b-btn>
            <b-btn accent block>Block</b-btn>
          </div>
        </div>
      </div>

      <div
        class="tw-w-full tw-h-full tw-bg-white tw-rounded tw-shadow-lg tw-p-8"
      >
        <h2
          class="tw-text-center tw-text-lg tw-uppercase tw-font-bold tw-tracking-wider tw-text-secondary tw-py-3"
        >
          Size
        </h2>

        <div class="tw-grid tw-grid-cols-5 tw-gap-4 tw-place-items-center">
          <template v-for="type in ['plain', 'outline', 'flat']">
            <template v-for="context in ['plain', 'primary']">
              <template v-for="size in ['xs', 'sm', 'base', 'lg', 'xl']">
                <b-btn
                  :key="`${type}-${context}-${size}`"
                  :outline="type === 'outline'"
                  :flat="type === 'flat'"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :primary="context === 'primary'"
                >
                  {{ size }}
                </b-btn>
              </template>
            </template>
          </template>
        </div>
      </div>

      <div
        class="tw-w-full tw-h-full tw-bg-white tw-rounded tw-shadow-lg tw-p-8"
      >
        <h2
          class="tw-text-center tw-text-lg tw-uppercase tw-font-bold tw-tracking-wider tw-text-secondary tw-py-3"
        >
          Size
        </h2>

        <div class="tw-grid tw-grid-cols-5 tw-gap-4 tw-place-items-center">
          <template v-for="type in ['plain', 'outline', 'flat']">
            <template v-for="context in ['loading', 'disabled']">
              <template v-for="size in ['xs', 'sm', 'base', 'lg', 'xl']">
                <b-btn
                  :key="`${type}-${context}-${size}`"
                  :outline="type === 'outline'"
                  :flat="type === 'flat'"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :disabled="context === 'disabled'"
                  :loading="context === 'loading'"
                >
                  {{ `${type} ${size}` }}
                </b-btn>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import BBtn from "@/components/common/input/button/BBtn";

export default {
  name: "buttons-development",
  components: { BBtn },
  data() {
    return {};
  },
  methods: {
    onClick(target = undefined) {
      console.log(`clicked ${target}!`);
    }
  }
};
</script>
