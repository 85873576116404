<template>
  <div>
    <h1>Development</h1>
    <data-provider-development class="tw-mb-4" />
    <buttons-development />
  </div>
</template>

<script type="text/babel">
import ButtonsDevelopment from "@/components/development/ButtonsDevelopment";
import DataProviderDevelopment from "@/components/development/DataProviderDevelopment";

export default {
  name: "fields-development",
  components: {
    DataProviderDevelopment,
    ButtonsDevelopment
  }
};
</script>
