import {
  ERRORS,
  IS_ERROR,
  IS_LOADED,
  IS_LOADING,
  OPTIONS
} from "./getter-types";
import { parseThrowableForErrorMessages } from "@buldit/common-fe";

export default {
  [IS_LOADING]: state => state.isLoading,
  [IS_LOADED]: state => state.isLoaded,
  [IS_ERROR]: state => !!state.throwable,
  [OPTIONS]: state => state.response?.data.data,
  [ERRORS]: (state, getters) =>
    getters[IS_ERROR]
      ? parseThrowableForErrorMessages(state.throwable)
      : undefined
};
