<template>
  <data-provider
    :api="api"
    :filters="filters"
    :fields="{
      question: 'questionable,isRoot,inheritedName,inheritedLabel'
    }"
    :include="
      [
        'questionable',
        'label',
        'parentQuestion.label',
        'parentQuestion.parentQuestion.label',
        'parentQuestion.parentQuestion.parentQuestion.label',
        'parentQuestion.parentQuestion.parentQuestion.parentQuestion.label',
        'parentQuestion.parentQuestion.parentQuestion.parentQuestion'
      ].join()
    "
    v-slot="{ loading, data, fetch, errors }"
  >
    <b-select
      v-bind="{
        // Pass on the props from BLocaleSelect
        ...$props,
        // But if props does not contain an error property, instead we pass on the
        // error messages provided by the data provider. This allows the error property
        // to override the data provider errors.
        error: $props.error || errors
      }"
      v-on="{
        ...$listeners,
        open: () => {
          fetch();
          $emit('open');
        },
        search: newSearch => {
          filters.search = newSearch;
          fetch();
          $emit('search', newSearch);
        }
      }"
      :loading="loading"
      :options="data"
    >
      <!-- Scoped slot 'selection' -->
      <template v-slot:selection="selectionSlotScope">
        <b-select-selection v-bind="selectionSlotScope">
          <template v-slot:default="{ option }">
            {{ getLabelFromOption(option) }}
          </template>
          <template v-slot:suffix="{ option }">
            <font-awesome-icon
              v-if="option.isRoot"
              :icon="['far', 'square-root']"
              fixed-width
              class="tw-text-gray-500"
              size="sm"
            />
            <b-icon-subject
              v-if="doesOptionHaveASubjectQuestionable(option)"
              :type="option.questionable.type"
              fixed-width
              class="tw-text-gray-500"
              size="sm"
            />
          </template>
        </b-select-selection>
      </template>

      <!-- Scoped slot 'option' -->
      <template v-slot:option="itemSlotScope">
        <b-select-option v-bind="itemSlotScope">
          <template
            v-slot="{ value, option, compareFn, highlightFn, isTruncated }"
          >
            <span
              class="tw-block"
              :class="{
                'tw-truncate': isTruncated,
                'tw-font-normal': !compareFn(value, option),
                'tw-font-semibold': compareFn(value, option)
              }"
            >
              <div v-html="highlightFn(getLabelFromOption(option))" />
              <div>
                <!-- Root or inherited? -->
                <font-awesome-icon
                  :icon="[
                    'far',
                    option.isRoot ? 'square-root' : 'square-root-alt'
                  ]"
                  fixed-width
                  size="sm"
                  class="tw-mr-1"
                />
                <span
                  class="tw-text-gray-500 tw-uppercase tw-font-bold tw-mr-1"
                  v-html="option.isRoot ? 'Root' : 'Inherited'"
                />
                <!-- Owner? -->
                <template
                  v-if="
                    option.questionable &&
                      doesOptionHaveASubjectQuestionable(option)
                  "
                  class="tw-uppercase"
                >
                  <b-icon-subject
                    :type="option.questionable.type"
                    fixed-width
                    size="sm"
                    class="tw-mr-0.5"
                  />
                  <span
                    class="tw-text-gray-500"
                    v-html="highlightFn(option.questionable.name)"
                  />
                </template>
                <span v-else-if="option.questionable" class="tw-text-gray-500">
                  Unexpected questionable: {{ option.questionable.id }}
                </span>
                <span v-else class="tw-text-gray-500"></span>
              </div>
            </span>
          </template>
        </b-select-option>
      </template>
    </b-select>
  </data-provider>
</template>

<script>
import BSelect from "@/components/common/input/select/BSelect";
import defaultSelectProps from "@/components/common/input/select/props.default";
import apiClient from "@/api/v2/question";
import BSelectSelection from "@/components/common/input/select/BSelectSelection";
import BSelectOption from "@/components/common/input/select/BSelectOption";
import { AUTH_USER_LOCALE_CODE } from "@/store/modules/locales/getter-types";
import BIconSubject from "@/components/common/icon/BIconSubject";

export default {
  name: "b-question-select",
  components: { BIconSubject, BSelectOption, BSelectSelection, BSelect },
  props: {
    ...defaultSelectProps(),
    searchable: {
      type: [Object, String, Boolean],
      default: "async"
    },
    state: {
      type: [Array, String],
      required: false
    },
    country: {
      type: [Object, Number],
      required: false
    },
    language: {
      type: [Object, Number],
      required: false
    },
    searchFn: {
      type: Function,
      default: (haystack, needle) =>
        haystack.filter(({ name }) => {
          if (needle.trim().length === 0) {
            return true;
          }
          return name.toLowerCase().includes(needle.toLowerCase());
        })
    },
    displayLocale: {
      type: [Object, String],
      required: false
    }
  },
  data() {
    return {
      filters: {
        search: undefined
      }
    };
  },
  computed: {
    api() {
      return apiClient;
    },
    internalLocale() {
      const displayLocale = this.displayLocale?.code || this.displayLocale;

      if (displayLocale) {
        return displayLocale;
      }

      return this.$store.getters[`locales/${AUTH_USER_LOCALE_CODE}`];
    }
  },
  methods: {
    getLabelFromOption({ name, inheritedName, label, inheritedLabel }) {
      return (
        name ||
        inheritedName ||
        inheritedLabel?.[this.internalLocale] ||
        label?.[this.internalLocale] ||
        "NO NAME OR LABEL"
      );
    },
    doesOptionHaveASubjectQuestionable({ questionable }) {
      return (
        questionable &&
        ["SECTOR", "SUBSECTOR", "JOB"].includes(questionable.type)
      );
    }
  }
};
</script>
