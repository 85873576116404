<template>
  <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start">
    <label
      :for="name"
      class="tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2"
    >
      {{ label }}
    </label>
    <div class="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
      <div
        class="tw-mb-2 tw-max-w-lg tw-rounded-md tw-shadow-sm sm:tw-max-w-xs"
      >
        <slot />
        <!--          <input -->
        <!--              id="email" -->
        <!--              class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"-->
        <!--          >-->
      </div>
      <div
        :id="`${name}-describedby`"
        :class="{ 'tw-pb-2': !!contextMessages }"
      >
        <template v-for="(messages, type) in contextMessages">
          <p
            v-for="(message, innerIndex) in messages"
            :key="`${type}-${innerIndex}`"
            class="tw-text-sm"
            :class="{
              'tw-text-error': type === 'errors',
              'tw-text-warning': type === 'warnings',
              'tw-text-success': type === 'successes',
              'tw-text-info': type === 'infos'
            }"
            style="margin-bottom: 0;"
          >
            {{ message }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import defaultLabelProps from "@/components/common/input/label/props.default";

export default {
  name: "inline-label",
  props: defaultLabelProps()
};
</script>
