export default () => ({
  label: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    default: undefined
  },
  contextMessages: {
    type: Object,
    default: undefined
  },
  hint: {
    type: String,
    default: undefined
  }
});
