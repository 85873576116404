<template>
  <div
    class="tw-my-2 tw-pb-1 tw-px-2 tw-rounded tw-text-center"
    :class="`tw-bg-${contextColor}-100`"
  >
    <span
      class="tw-text-xs tw-font-bold tw-uppercase"
      :class="`tw-text-${contextColor}-400`"
      >Validation Observer State</span
    >
    <div class="tw-grid tw-grid-cols-7 tw-gap-4 tw-items-start">
      <div
        v-for="state in [
          'dirty',
          'pristine',
          'touched',
          'untouched',
          'pending',
          'valid',
          'invalid'
        ]"
        :key="state"
      >
        <div
          class="tw-text-xs tw-uppercase"
          :class="{
            'tw-font-bold': validationProps[state] !== defaultFlags[state]
          }"
        >
          {{ state }}
        </div>
        <div
          :class="
            validationProps[state] ? 'tw-text-green-500' : 'tw-text-red-500'
          "
        >
          <font-awesome-icon
            :icon="
              validationProps[state]
                ? ['fad', 'check-square']
                : ['fad', 'times-square']
            "
          />
        </div>
      </div>
    </div>
    <template v-if="errors.length > 0">
      <ul class="tw-text-red-500">
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script type="text/babel">
export default {
  name: "validation-observer-debugger",
  props: {
    validationProps: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    defaultFlags: {
      dirty: false,
      pristine: true,
      touched: false,
      untouched: true,
      pending: false,
      valid: false,
      invalid: false
    }
  }),
  computed: {
    contextColor() {
      if (this.validationProps.invalid) {
        return "red";
      }
      if (this.validationProps.valid) {
        return "green";
      }
      return "gray";
    },
    errors() {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.validationProps.errors)
        .filter(([, value]) => value.length > 0)
        .reduce((carry, [key, value]) => {
          value.forEach((value, index) =>
            carry.push(`Provider ${key}.${index}: ${value}`)
          );
          return carry;
        }, []);
    }
  }
};
</script>
