var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('renderless-select',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var value = ref.value;
  var hasValue = ref.hasValue;
  var name = ref.name;
  var isMultiple = ref.isMultiple;
  var isSearchable = ref.isSearchable;
  var isDisabled = ref.isDisabled;
  var isOpen = ref.isOpen;
  var isTruncated = ref.isTruncated;
  var options = ref.options;
  var highlightedIndex = ref.highlightedIndex;
  var contextMessages = ref.contextMessages;
  var errors = ref.errors;
  var toggle = ref.toggle;
  var open = ref.open;
  var close = ref.close;
  var select = ref.select;
  var displayFn = ref.displayFn;
  var compareFn = ref.compareFn;
  var searchProps = ref.searchProps;
  var searchEvents = ref.searchEvents;
  var activatorEvents = ref.activatorEvents;
return [_c('validation-provider',{ref:"validator",attrs:{"rules":_vm.rules,"name":name},scopedSlots:_vm._u([{key:"default",fn:function(validatorSlotScope){return [_c('label-provider',{attrs:{"label":_vm.$props.label,"name":name,"inline":_vm.$props.inline,"context-messages":Object.assign({}, contextMessages,
        {errors: contextMessages.errors
          ? contextMessages.errors
          : validatorSlotScope.errors})}},[_c('on-click-outside-provider',{attrs:{"do":close}},[_c('div',{staticClass:"tw-relative tw-reset",attrs:{"aria-invalid":(validatorSlotScope.errors.length > 0).toString(),"aria-describedby":(name + "-describedby")}},[_c('span',{staticClass:"tw-inline-block tw-w-full tw-rounded-md tw-shadow-sm"},[_c('button',_vm._g({ref:"button",class:[
                _vm.btnClass,
                [
                  'b-select__btn',
                  'tw-cursor-pointer',
                  'tw-relative',
                  'tw-w-full',
                  'tw-rounded-md',
                  'tw-border',
                  'tw-border-gray-300',
                  'tw-bg-white',
                  'tw-pl-3',
                  'tw-pr-10',
                  'tw-py-2',
                  'tw-text-left',
                  'focus:tw-outline-none',
                  'focus:tw-shadow-outline-blue',
                  'focus:tw-border-blue-300',
                  'tw-transition',
                  'tw-ease-in-out',
                  'tw-duration-150',
                  'sm:tw-text-sm',
                  'sm:tw-leading-5'
                ] ].concat( (isOpen
                  ? [
                      'tw-outline-none',
                      'tw-shadow-outline-blue',
                      'tw-border-blue-300'
                    ]
                  : [])
              ),staticStyle:{"border-width":"1px","border-style":"solid"},attrs:{"type":"button","disabled":isDisabled,"aria-haspopup":"listbox","aria-expanded":isOpen ? 'true' : 'false',"aria-labelledby":"listbox-label"}},activatorEvents),[_vm._t("selection",[_c('b-select-selection',_vm._b({},'b-select-selection',{
                    value: value,
                    isMultiple: isMultiple,
                    isTruncated: isTruncated,
                    hasValue: hasValue,
                    displayFn: displayFn,
                    placeholder: _vm.placeholder
                  },false))],null,{
                  value: value,
                  isMultiple: isMultiple,
                  isTruncated: isTruncated,
                  hasValue: hasValue,
                  displayFn: displayFn,
                  placeholder: _vm.placeholder
                }),_c('span',{staticClass:"tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-pointer-events-none"},[(_vm.loading)?_c('font-awesome-icon',{staticClass:"tw-text-gray-400",attrs:{"icon":['fad', 'spinner'],"size":"lg","pulse":""}}):(errors && errors.length > 0)?_c('font-awesome-icon',{staticClass:"tw-text-error",attrs:{"size":"lg","icon":['far', 'exclamation-circle']}}):_c('font-awesome-icon',{staticClass:"tw-text-gray-400",attrs:{"size":"lg","icon":['fal', 'sort']}})],1)],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isOpen),expression:"isOpen"}],ref:"dropdown",staticClass:"b-select__dropdown tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-rounded-md tw-bg-white tw-shadow-lg tw-p-2"},[_c('input',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(isSearchable),expression:"isSearchable"}],ref:"search",class:[
                _vm.searchClass ].concat( [
                  'tw-flex-1',
                  'tw-form-input',
                  'tw-block',
                  'tw-w-full',
                  'tw-min-w-0',
                  'tw-rounded-md',
                  'tw-transition',
                  'tw-duration-150',
                  'tw-ease-in-out',
                  'sm:tw-text-sm',
                  'sm:tw-leading-5',
                  'tw-border',
                  'tw-border-solid',
                  'focus:tw-shadow-none',
                  'tw-mb-2'
                ]
              ),attrs:{"disabled":isDisabled,"autocomplete":"new-password"}},'input',searchProps,false),searchEvents)),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(options.length > 0),expression:"options.length > 0"}],ref:"options",staticClass:"b-select__options tw-max-h-60 tw-py-1 tw-text-base tw-leading-6 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm sm:tw-leading-5",attrs:{"tabindex":"-1","role":"listbox","aria-labelledby":"listbox-label","aria-activedescendant":("b-select__option-" + highlightedIndex)}},[_vm._l((options),function(option,index){return _vm._t("option",[_c('b-select-option',_vm._b({},'b-select-option',{
                    value: value,
                    option: option,
                    search: isSearchable ? searchProps.value : undefined,
                    index: index,
                    displayFn: displayFn,
                    compareFn: compareFn,
                    select: select,
                    highlightedIndex: highlightedIndex,
                    isTruncated: isTruncated
                  },false))],null,{
                  option: option,
                  search: isSearchable ? searchProps.value : undefined,
                  index: index,
                  displayFn: displayFn,
                  compareFn: compareFn,
                  select: select,
                  highlightedIndex: highlightedIndex,
                  isTruncated: isTruncated
                })})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && options.length === 0),expression:"loading && options.length === 0"}],staticClass:"tw-px-3 tw-text-gray-400 tw-text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'spinner'],"pulse":""}}),_vm._v(" Loading ... ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && options.length === 0),expression:"!loading && options.length === 0"}],staticClass:"tw-px-3 tw-text-gray-400 tw-text-center"},[_vm._v(" No results found for \"\" ")])])])])],1)]}}],null,true)})]}}])},'renderless-select',Object.assign({}, _vm.$props,
    // But if props does not contain an error property, instead we pass on the error
    // bag provided by the validation provider. This allows the error property to
    // override the default validation.
    {error: _vm.$props.error}),false),Object.assign({}, _vm.$listeners,
    // But because vee-validate's ValidationProvider normally works using v-model, we
    // have to trigger the validation ourselves. We do this by overriding the 'input'
    // listener and passing the new value to the validate method we get from the
    // wrapping ValidationProvider - before calling BSelect's default input listener!
    // Failing to do so will stop the input's value from propagating.
    // We also want validation to trigger when closing the component, so we override
    // that listener as well to call validate before calling BSelect's default close
    // listeners if there was one.
    // Keep in mind that when validating on both input and close, we'll get double
    // validate invocations so we'll need to debouncing any async validation calls.
    {input: function (newValue) {
      if (_vm.rules && _vm.$refs.validator) {
        _vm.$refs.validator.validate(newValue);
        _vm.$refs.validator.flags.dirty = true;
        _vm.$refs.validator.flags.pristine = false;
      }

      _vm.$listeners.input(newValue);
    },
    close: function (currentValue) {
      if (_vm.rules && _vm.$refs.validator) {
        _vm.$refs.validator.validate(currentValue);
        _vm.$refs.validator.flags.untouched = false;
        _vm.$refs.validator.flags.touched = true;
      }

      if (_vm.$listeners.close) {
        _vm.$listeners.close();
      }
    }})))}
var staticRenderFns = []

export { render, staticRenderFns }