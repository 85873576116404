<template>
  <div>
    <div class="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 md:tw-px-8">
      <h1 class="tw-text-2xl tw-font-semibold tw-text-gray-900">Selects</h1>
    </div>
    <div class="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 md:tw-px-8">
      <div class="tw-py-4">
        <b-select-development />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import BSelectDevelopment from "@/components/development/BSelectDevelopment";

export default {
  name: "development-select-view",
  components: { BSelectDevelopment }
};
</script>
