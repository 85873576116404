<template>
  <data-provider
    :api="api"
    :filters="filters"
    :fields="{ company: 'vat_number' }"
    v-slot="{ loading, data, fetch, errors }"
  >
    <b-select
      v-bind="{
        // Pass on the props from BLocaleSelect
        ...$props,
        // But if props does not contain an error property, instead we pass on the
        // error messages provided by the data provider. This allows the error property
        // to override the data provider errors.
        error: $props.error || errors
      }"
      v-on="{
        ...$listeners,
        open: () => {
          fetch();
          $emit('open');
        },
        search: newSearch => {
          filters.search = newSearch;
          fetch();
          $emit('search', newSearch);
        }
      }"
      :loading="loading"
      :options="data"
    >
      <!-- Scoped slot 'selection' -->
      <template v-slot:selection="selectionSlotScope">
        <b-select-selection v-bind="selectionSlotScope">
          <template v-slot:suffix="{ option }">
            <font-awesome-icon
              v-if="option.state === 'PAUSED'"
              :icon="['far', 'pause-circle']"
              class="tw-ml-1"
              size="lg"
            />
          </template>
        </b-select-selection>
      </template>

      <!-- Scoped slot 'option' -->
      <template v-slot:option="itemSlotScope">
        <b-select-option v-bind="itemSlotScope">
          <template
            v-slot:default="{
              value,
              option,
              compareFn,
              highlightFn,
              isTruncated
            }"
          >
            <span
              class="tw-block"
              :class="{
                'tw-truncate': isTruncated,
                'tw-font-normal': !compareFn(value, option),
                'tw-font-semibold': compareFn(value, option)
              }"
            >
              <div>
                <span v-html="highlightFn(option.name)" />
                <font-awesome-icon
                  v-if="option.state === 'PAUSED'"
                  :icon="['far', 'pause-circle']"
                  class="tw-ml-1"
                  size="lg"
                />
              </div>
              <div v-html="highlightFn(option.vatNumber)" />
            </span>
          </template>
        </b-select-option>
      </template>

      <!-- Scoped slot 'option' -->
      <template v-slot:option="itemSlotScope">
        <b-select-option v-bind="itemSlotScope">
          <template
            v-slot="{ value, option, isTruncated, compareFn, highlightFn }"
          >
            <span
              class="tw-block"
              :class="{
                'tw-truncate': isTruncated,
                'tw-font-normal': !compareFn(value, option),
                'tw-font-semibold': compareFn(value, option)
              }"
            >
              <div>
                <span v-html="highlightFn(option.name)"></span>
                <font-awesome-icon
                  v-if="option.state === 'PAUSED'"
                  :icon="['far', 'pause-circle']"
                  class="tw-ml-1"
                  size="lg"
                />
              </div>
              <div v-html="highlightFn(option.vatNumber)"></div>
            </span>
          </template>
        </b-select-option>
      </template>
    </b-select>
  </data-provider>
</template>

<script>
import BSelect from "@/components/common/input/select/BSelect";
import defaultSelectProps from "@/components/common/input/select/props.default";
import apiClient from "@/api/v2/affiliate";
import BSelectSelection from "@/components/common/input/select/BSelectSelection";
import BSelectOption from "@/components/common/input/select/BSelectOption";

export default {
  name: "b-affiliate-select",
  components: { BSelectOption, BSelectSelection, BSelect },
  props: {
    ...defaultSelectProps(),
    searchable: {
      type: [Object, String, Boolean],
      default: "async"
    },
    state: {
      type: [String, Array],
      required: false
    },
    country: {
      type: [Number, Object],
      required: false
    },
    language: {
      type: [Number, Object],
      required: false
    },
    displayFn: {
      type: Function,
      default: option => `${option?.name}`
    },
    searchFn: {
      type: Function,
      default: (haystack, needle) =>
        haystack.filter(({ name }) => {
          if (needle.trim().length === 0) {
            return true;
          }
          return name.toLowerCase().includes(needle.toLowerCase());
        })
    }
  },
  data() {
    return {
      filters: {
        search: undefined,
        state: this.state,
        country: Number.isInteger(this.country)
          ? this.country
          : this.country?.id,
        language: Number.isInteger(this.language)
          ? this.language
          : this.language?.id
      }
    };
  },
  computed: {
    api() {
      return apiClient;
    }
  }
};
</script>
