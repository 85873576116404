var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-provider',{attrs:{"store":_vm.store,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var data = ref.data;
var fetch = ref.fetch;
var errors = ref.errors;
return [_c('b-select',_vm._g(_vm._b({attrs:{"loading":loading,"options":data},scopedSlots:_vm._u([{key:"selection",fn:function(selectionSlotScope){return [_c('b-select-selection',_vm._b({scopedSlots:_vm._u([{key:"suffix",fn:function(ref){
var option = ref.option;
return [_c('b-icon-subject',{staticClass:"tw-ml-1",attrs:{"type":option.type,"size":"sm"}})]}}],null,true)},'b-select-selection',selectionSlotScope,false))]}},{key:"option",fn:function(itemSlotScope){return [_c('b-select-option',_vm._b({scopedSlots:_vm._u([{key:"suffix",fn:function(ref){
var option = ref.option;
return [_c('b-icon-subject',{staticClass:"tw-ml-1",attrs:{"type":option.type,"size":"sm"}})]}}],null,true)},'b-select-option',itemSlotScope,false))]}}],null,true)},'b-select',Object.assign({}, _vm.$props,
      // But if props does not contain an error property, instead we pass on the
      // error messages provided by the data provider. This allows the error property
      // to override the data provider errors.
      {error: _vm.$props.error || errors}),false),Object.assign({}, _vm.$listeners,
      {open: function () {
        fetch();
        _vm.$emit('open');
      },
      search: function (newSearch) {
        _vm.filters.search = newSearch;
        fetch();
        _vm.$emit('search', newSearch);
      }})))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }