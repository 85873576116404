<template>
  <div>
    <router-link :to="{ name: 'development.tailwind' }" tag="button">
      Tailwind
    </router-link>
    <fields-development />
  </div>
</template>

<script type="text/babel">
import FieldsDevelopment from "@/components/development/FieldsDevelopment";

export default {
  name: "development-view",
  components: { FieldsDevelopment }
};
</script>
