import generateState from "@/store/templates/common-select/state";
import selectGetters from "@/store/templates/common-select/getters";
import selectActions from "@/store/templates/common-select/actions";
import selectMutations from "@/store/templates/common-select/mutations";

export default apiModule => ({
  namespaced: true,
  state: generateState(),
  getters: selectGetters,
  actions: selectActions(apiModule),
  mutations: selectMutations
});
